import { Link as RemixLink } from "@remix-run/react";

import {
  Heading,
  Text,
  Box,
  Button,
  Link,
  Skeleton,
  OrderedList,
  ListItem,
  VStack,
  StackDivider,
  SimpleGrid,
  useColorModeValue as mode,
  useBreakpointValue as bp,
} from "@chakra-ui/react";

import { ArrowForwardIcon } from "@chakra-ui/icons";

// import * as Logos from "~/components/Logos";

import {
  FacilitySearchCombobox,
  ReviewWidget,
  Paper,
  RecentOpenings,
  CallToAction,
} from "~/components";

export function headers() {
  // This is the only page we can correctly cache since we have a redirect rule in nginx
  // and there are no client-side links to this page when logged-in
  //
  // by adding a redirect rule in nginx, we essentially move the authed redirect logic
  // from the origin server to the edge network, making these requests much faster
  return {
    "Cache-Control": `public, max-age=${0}, s-maxage=${60 * 60 * 24 * 30}`,
  };
}

export function meta() {
  return [
    { title: "Campground fully booked? Schnerp it!" },
    {
      name: "description",
      content:
        "Tell us where and when you want to camp, and we'll notify you if a site opens up. It's completely free to try.",
    },
  ];
}

export default function Index() {
  return (
    <>
      <VStack spacing={{ base: 8, md: 20 }} divider={<StackDivider />}>
        <Hero />
        <HowItWorks />
        <Reviews />
        <Pricing />
      </VStack>
      <CallToAction
        maxW="container.lg"
        mx="auto"
        heading="Try today for free"
        body="We'll start you off with 10 free notifications, so you can dial in your requests and schnerp one on us"
        buttonText="Find a campground"
        buttonProps={{
          as: RemixLink,
          to: "/new",
        }}
      />
    </>
  );
}

function Hero() {
  return (
    <SimpleGrid
      columns={{
        base: 1,
        lg: 2,
      }}
      p={{ base: 4, md: 0 }}
      mt={{ base: 0, md: 8 }}
      spacing="12"
    >
      <Box>
        <Heading
          as="h1"
          // size="2xl"
          size="3xl"
          // fontSize="5xl"
          letterSpacing="tight"
          fontWeight="extrabold"
        >
          Campground fully&nbsp;booked?
          <Text as="span" color="warning">
            {" "}
            Schnerp&nbsp;it!
          </Text>
        </Heading>
        <Text mt="6" fontSize="xl">
          We check reservation websites for cancellations, and notify you when a
          spot opens up. <Text as="strong">It's completely free to try.</Text>
        </Text>
        <Button
          mt="8"
          variant="primary"
          fontWeight="extrabold"
          size="xl"
          minW="12rem"
          as={RemixLink}
          to="/new"
        >
          Find a campground
        </Button>

        <Text color="subtle" mt="8">
          Already have an account?{" "}
          <Button
            variant="link"
            colorScheme="brand"
            rightIcon={<ArrowForwardIcon />}
            as={RemixLink}
            to="/login"
          >
            Log in
          </Button>
        </Text>
      </Box>
      <Box>
        <Heading size="sm" fontWeight="extrabold" mb={4}>
          Try it now
        </Heading>
        <Paper>
          <FacilitySearchCombobox fontSize="xl" />
        </Paper>
      </Box>
    </SimpleGrid>
  );
}

function HowItWorks() {
  return (
    <SimpleGrid
      columns={{
        base: 1,
        lg: 2,
      }}
      p={{ base: 4, md: 0 }}
      spacing="12"
    >
      <Box>
        <Heading size="md" fontWeight="extrabold" mb={{ base: 6, md: 8 }}>
          How it works
        </Heading>
        <OrderedList fontSize="lg" spacing={4} pl={4}>
          <ListItem>
            <Link as={RemixLink} to="/new" fontWeight="bold">
              Create a request
            </Link>
            &nbsp;- Select your campground, arrival dates, and preferred sites.
            <Text as="em"> We do backcountry and day trips too!</Text>
          </ListItem>
          <ListItem>
            <Text as="strong">Wait for a notification</Text> - We'll regularly
            refresh the reservation site so you don't have to.{" "}
          </ListItem>
          <ListItem>
            <Text as="strong">Book that spot</Text> - If we find an opening,
            we'll send you an email or text. Click the link to reservation page,
            and book that spot.
          </ListItem>
        </OrderedList>
        <Text color="subtle" mt={8}>
          Want to learn more?{" "}
          <Button
            variant="link"
            colorScheme="brand"
            rightIcon={<ArrowForwardIcon />}
            as={RemixLink}
            to="/faq"
          >
            Read the FAQs
          </Button>
        </Text>
      </Box>

      <Box>
        <RecentOpenings mx="auto" py={8} maxW="400px" />
        <Box py={{ base: 4, md: 8 }}></Box>
      </Box>
    </SimpleGrid>
  );
}

function Reviews() {
  return (
    <SimpleGrid
      columns={{
        base: 1,
        lg: 2,
      }}
      p={{ base: 4, md: 0 }}
      spacing="12"
    >
      <Box>
        <Heading size="md" fontWeight="extrabold" mb={{ base: 6, md: 8 }}>
          Avoid opening day
        </Heading>
        <Text fontSize="lg" mb={8}>
          Not everyone can book on opening day. And if you can, someone still
          might beat you to it.
        </Text>
        <Text fontSize="lg" mb={8}>
          We're here for the procrastinators, the new parents, the backcountry
          planners, the hard workers, and{" "}
          <Text as="strong">for anyone who can't book so far in advance</Text>.
        </Text>
        <Text mb={8} color="subtle">
          Not convinced?{" "}
          <Button
            variant="link"
            colorScheme="brand"
            rightIcon={<ArrowForwardIcon />}
            as={RemixLink}
            to="/reviews"
          >
            See all reviews
          </Button>
        </Text>
      </Box>

      <Paper p={8}>
        <ReviewWidget
          lightToken="OJ4teUxMpZZxZIcmc539GzUO1eoD5QGp8to0QNJwmkvNimXNDb"
          darkToken="QJD8C03nO9wCw4OIi34oc4ZB4mJbFZWfcsbFNvOIri6kqEOYv1"
        >
          <Skeleton minH="350px"></Skeleton>
        </ReviewWidget>
      </Paper>
    </SimpleGrid>
  );
}

function Pricing() {
  return (
    <SimpleGrid
      columns={{
        base: 1,
        lg: 2,
      }}
      p={{ base: 4, md: 0 }}
      spacing="12"
    >
      <Box>
        <Heading size="md" fontWeight="extrabold" mb={{ base: 6, md: 8 }}>
          Try for free,{" "}
          <Text as="span" whiteSpace="nowrap">
            subscribe for more
          </Text>
        </Heading>
        <Text fontSize="lg" mb={8}>
          We'll start you{" "}
          <Text as="strong">with 10 free email notifications,</Text> so you can
          dial in your requests and schnerp a couple on us.
        </Text>
        <Text fontSize="lg" mb={8}>
          If you need more, or want premium options like text messages, you can{" "}
          <Link as={RemixLink} to="/pricing">
            subscribe to a paid plan.
          </Link>
        </Text>
      </Box>

      <Button
        maxWidth="350px"
        alignSelf="center"
        size="lg"
        variant="secondary"
        rightIcon={<ArrowForwardIcon />}
        as={RemixLink}
        to="/pricing"
      >
        Read about our pricing
      </Button>
    </SimpleGrid>
  );
}
